import React, { useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import { useParams } from 'react-router-dom';
import { createSession, connectWebSocket, request_action, on_response_for_datachannel, on_response_for_websocket, connectWebRTC } from '../../utils/api';
import Header from '../../components/Header/Header';
import styles from './PlayWorld.module.css';

const PixiContainer = ({ websocket, datachannel }) => {
    const pixiContainerRef = useRef(null);
    const [pixiApp, setPixiApp] = useState(null)
    const bitmaps = useRef([])

    const colorMap = {
        default: 0xFFFFFF,
        player: 0xE8FF00,
        seen: 0x9300FF,
        special: 0x001BFF
    };


    useEffect(() => {
        let app = null

        const handleKeydown = (event) => {
            const keyMap = {
                'ArrowUp': 'post_input_move_north',
                'w': 'post_input_move_north',
                'ArrowDown': 'post_input_move_south',
                's': 'post_input_move_south',
                'ArrowLeft': 'post_input_move_west',
                'a': 'post_input_move_west',
                'ArrowRight': 'post_input_move_east',
                'd': 'post_input_move_east'
            };
            const keyAction = keyMap[event.key]

            if (keyAction) {
                request_action(websocket, keyAction, {})
            }
        }

        const load = async () => {
            if (!app) {
                const app = new PIXI.Application()
                await app.init({
                    resizeTo: pixiContainerRef.current,
                    backgroundColor: 0x000000,
                });

                document.addEventListener('keydown', handleKeydown);
                setPixiApp(app)
            }
        }

        load()

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        }
    }, [websocket])

    const initializeMap = () => {
        const tilesize = 16;
        const vpheight = Math.floor(pixiApp.screen.height / 16);
        const vpwidth = Math.floor(pixiApp.screen.width / 16);

        if (bitmaps.current.length === 0) {
            for (let y = 0; y < vpheight; y++) {
                for (let x = 0; x < vpwidth; x++) {
                    const bitmapFontText = new PIXI.BitmapText({
                        text: '',
                        style: {
                            fontFamily: 'monospace',
                            fontSize: tilesize,
                        },
                        x: x * tilesize,
                        y: y * tilesize
                    });
                    pixiApp.stage.addChild(bitmapFontText);
                    bitmaps.current.push(bitmapFontText);
                }
            }
        }
    }

    const renderScreen = async () => {
        try {
            on_response_for_datachannel(datachannel, 'get_player_current_area', {}, ({ playerPosition, mapSize }) => {
                const tilesize = 16;
                const viewportHeight = Math.floor(pixiApp.screen.height / 16);
                const viewportWidth = Math.floor(pixiApp.screen.width / 16);
                let startX = Math.max(0, Math.min(mapSize.width - viewportWidth, playerPosition.x - Math.floor(viewportWidth / 2)));
                let startY = Math.max(0, Math.min(mapSize.height - viewportHeight, playerPosition.y - Math.floor(viewportHeight / 2)));
                let width = viewportWidth;
                let height = viewportHeight;

                on_response_for_datachannel(datachannel, 'get_render', {
                    startX,
                    startY,
                    width,
                    height
                }, ({ map, tilemap, visible_objects }) => {
                    let object_positions = new Map();

                    for (let visibleObject of visible_objects) {
                        const key = `${visibleObject.x - startX},${visibleObject.y - startY}`;
                        object_positions.set(key, visibleObject);
                    }

                    let index = 0;
                    for (let y = 0; y < viewportHeight; y++) {
                        for (let x = 0; x < viewportWidth; x++) {
                            const tileId = map[y][x];  // Get tile identifier
                            let tileChar = tilemap[tileId].char;  // Retrieve the character for rendering
                            let tileColor = colorMap[tilemap[tileId].color];

                            const key = `${x},${y}`;
                            if (object_positions.has(key)) {
                                let object = object_positions.get(key);
                                tileChar = object.char;
                                tileColor = colorMap[object.color];
                            }

                            const bitmapFontText = bitmaps.current[index++];
                            bitmapFontText.text = tileChar;
                            bitmapFontText.style.fill = tileColor;
                            bitmapFontText.x = x * tilesize;
                            bitmapFontText.y = y * tilesize;
                        }
                    }

                    requestAnimationFrame(renderScreen)
                })
            })
        } catch (error) {
            console.error('Error in renderScreen:', error);
        }
    };

    if (pixiApp) {
        pixiContainerRef.current.appendChild(pixiApp.canvas)
        initializeMap()
        on_response_for_websocket(websocket, 'post_start_game', {}, () => {
            requestAnimationFrame(renderScreen)
        })
    }

    return (
        <>
            <div className={styles.game_window} ref={pixiContainerRef}></div>
        </>
    )
}

const PlayPage = () => {
    const { world_instance_id } = useParams();
    const [websocket, setWebsocket] = useState(null);
    const [datachannel, setDatachannel] = useState(null);

    useEffect(() => {
        let _ws = null
        let _dc = null

        const setup = async () => {
            if (!_ws) {
                const { session_id, token } = await createSession(world_instance_id);
                _ws = await connectWebSocket("wss://" + window.location.host + '/ws/' + session_id, token);
                _dc = await connectWebRTC(session_id, _ws, 'rendering')
                setWebsocket(_ws)
                setDatachannel(_dc)
            }
        }

        setup();

        return () => {
            if (_ws) {
                _ws.close()
                setWebsocket(null)
                setDatachannel(null)
            }
        };
    }, [world_instance_id]);

    const websocketIsReady = websocket && websocket.readyState === WebSocket.OPEN
    const datachannelIsReady = datachannel && datachannel.readyState === "open"

    return (
        <div>
            <Header />
            {
                websocketIsReady && datachannelIsReady ?
                    <PixiContainer websocket={websocket} datachannel={datachannel}></PixiContainer>
                    :
                    <></>
            }
        </div>
    );
};

export default PlayPage;