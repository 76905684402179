import { v4 as uuidv4 } from 'uuid';
import pako from 'pako';

function decompressBase64Gzip(base64String) {
    const byteArray = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
    return pako.ungzip(byteArray, { to: 'string' });
}

const registered_websocket_handlers = {};
const registered_datachannel_handlers = {};

export async function createSession(world_instance_id) {
    const response = await fetch(`/api/game_sessions`, { method: 'POST', body: JSON.stringify({ world_instance_id }) });
    if (response.ok) {
        const data = await response.json();
        return data;
    }
    throw new Error('Game session creation failed');
}


export function register_handler(registry, action, handler) {
    if (!registry[action]) {
        registry[action] = [];
    }
    registry[action].push(handler);
}

export function unregister_handler(registry, action, handler) {
    if (registry[action]) {
        registry[action] = registry[action].filter(h => h !== handler);
        if (registry[action].length === 0) {
            delete registry[action];
        }
    }
}

export function request_action(ws, action, data) {
    ws.send(JSON.stringify({
        action: action + '_request',
        data: data
    }))
}

export const on_response_for_websocket = (ws, action_handle, data, callback) => {
    let randomId = uuidv4();
    const responseEvent = action_handle + `_response#${randomId}`;

    const responseHandler = (data) => {
        unregister_handler(registered_websocket_handlers, responseEvent, responseHandler);
        callback(JSON.parse(data));
    }

    register_handler(registered_websocket_handlers, responseEvent, responseHandler);

    try {
        if (ws.readyState !== WebSocket.OPEN) {
            throw new Error('Socket state not open')
        }

        ws.send(JSON.stringify({
            action: action_handle + `_request#${randomId}`,
            data: data
        }));
    } catch (err) {
        throw err
    }
}

export const on_response_for_datachannel = (datachannel, action_handle, data, callback) => {
    let randomId = uuidv4();
    const responseEvent = action_handle + `_response#${randomId}`;

    const responseHandler = (data) => {
        unregister_handler(registered_datachannel_handlers, responseEvent, responseHandler);
        callback(JSON.parse(data));
    }

    register_handler(registered_datachannel_handlers, responseEvent, responseHandler);

    try {
        if (datachannel.readyState !== "open") {
            throw new Error('Data channel not open')
        }

        datachannel.send(JSON.stringify({
            action: action_handle + `_request#${randomId}`,
            data: data
        }));
    } catch (err) {
        throw err
    }
}

function handleStreamedMessage(registry, is_compressed, event) {
    let message_data = event.data

    if (is_compressed) {
        message_data = decompressBase64Gzip(message_data)
    }

    const message = JSON.parse(message_data);
    const { action, data } = message;

    const handlers = registry[action]

    if (handlers && handlers.length > 0) {
        for (let handler of handlers) {
            handler(data);
        }
    }
}

let existingWebSockets = {};
let socketResolvers = {};
let socketRejecters = {};

export async function connectWebSocket(session_id, token) {
    return new Promise((resolve, reject) => {
        if (session_id in existingWebSockets) {
            socketResolvers[session_id].push(resolve)
            socketRejecters[session_id].push(reject)

            if (existingWebSockets[session_id].readyState === WebSocket.OPEN) {
                resolve(existingWebSockets[session_id])
            }

            if (existingWebSockets[session_id].readyState === WebSocket.CLOSED || existingWebSockets[session_id].readyState === WebSocket.CLOSING) {
                reject(new Error("WebSocket close"))
            }
        } else {
            const ws = new WebSocket(session_id);
            existingWebSockets[session_id] = ws
            socketResolvers[session_id] = []
            socketRejecters[session_id] = []

            socketResolvers[session_id].push(resolve)
            socketRejecters[session_id].push(reject)

            ws.onopen = () => {
                ws.send(token);

                while (socketResolvers[session_id].length) {
                    const resolver = socketResolvers[session_id].pop()
                    resolver(ws)
                }
            };

            ws.onclose = () => {
                console.log("WebSocket is closed now.");

                while (socketRejecters[session_id].length) {
                    const rejecter = socketRejecters[session_id].pop()
                    rejecter(new Error("WebSocket error"))
                }

                delete existingWebSockets[session_id]
                delete socketResolvers[session_id]
                delete socketRejecters[session_id]
            };

            ws.onmessage = handleStreamedMessage.bind(null, registered_websocket_handlers, false);

            ws.onerror = (err) => {
                while (socketRejecters[session_id].length) {
                    const rejecter = socketRejecters[session_id].pop()
                    rejecter(new Error("WebSocket error"))
                }
            };
        }
    });
}

let existingWebRTCConnections = {};
let rtcResolvers = {};
let rtcRejecters = {};

const RTCIceConnectionState = {
    NEW: 'new',
    CHECKING: 'checking',
    CONNECTED: 'connected',
    COMPLETED: 'completed',
    FAILED: 'failed',
    DISCONNECTED: 'disconnected',
    CLOSED: 'closed'
};

const RTCSignalingState = {
    STABLE: 'stable',
    HAVE_LOCAL_OFFER: 'have-local-offer',
    HAVE_REMOTE_OFFER: 'have-remote-offer',
    HAVE_LOCAL_PRANSWER: 'have-local-pranswer',
    HAVE_REMOTE_PRANSWER: 'have-remote-pranswer',
    CLOSED: 'closed'
};

export async function connectWebRTC(session_id, websocket, label) {
    return new Promise(async (resolve, reject) => {
        if (session_id in existingWebRTCConnections) {
            rtcResolvers[session_id].push(resolve)
            rtcRejecters[session_id].push(reject)
        } else {
            const peerConnection = new RTCPeerConnection({
                iceServers: [{
                    urls: 'stun:stun.l.google.com:19302'
                }]
            });
            const dataChannel = peerConnection.createDataChannel(label);
            existingWebRTCConnections[session_id] = dataChannel
            rtcResolvers[session_id] = []
            rtcRejecters[session_id] = []
            rtcResolvers[session_id].push(resolve)
            rtcRejecters[session_id].push(reject)

            const handleWebRTCSignalSetup = async (data) => {
                data = JSON.parse(data)
                try {
                    if (data.sdp) {
                        await peerConnection.setRemoteDescription(new RTCSessionDescription(data));
                    } else if (data.candidate) {
                        await peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
                    }
                } catch (error) {
                    reject(error);
                }
            }

            const resolveAll = () => {
                while (rtcResolvers[session_id].length) {
                    const resolver = rtcResolvers[session_id].pop()
                    resolver(dataChannel)
                }
            }

            const rejectAll = (err) => {
                while (rtcRejecters[session_id].length) {
                    const rejecter = rtcRejecters[session_id].pop()
                    rejecter(err)
                }
            }

            const closeSession = () => {
                delete existingWebRTCConnections[session_id]
                delete rtcResolvers[session_id]
                delete rtcRejecters[session_id]
            }

            register_handler(registered_websocket_handlers, 'post_web_rtc_signal_response', handleWebRTCSignalSetup);

            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    request_action(websocket, 'post_web_rtc_signal', { candidate: event.candidate });
                }
            };

            peerConnection.ondatachannel = (event) => {
                const channel = event.channel;
                channel.onmessage = (event) => {
                    console.log('Data Channel Message: ', event.data);
                };
            };

            peerConnection.oniceconnectionstatechange = () => {
                console.log('ICE Connection State:', peerConnection.iceConnectionState);

                if (peerConnection.iceConnectionState === RTCIceConnectionState.FAILED ||
                    peerConnection.iceConnectionState === RTCIceConnectionState.DISCONNECTED ||
                    peerConnection.iceConnectionState === RTCIceConnectionState.CLOSED) {
                    rejectAll(new Error(`ICE connection state is ${peerConnection.iceConnectionState}`))
                }
            };

            peerConnection.onsignalingstatechange = () => {
                console.log('Signaling State:', peerConnection.signalingState);

                if (peerConnection.signalingState === RTCSignalingState.CLOSED) {
                    rejectAll(new Error(`Signaling state is ${peerConnection.iceConnectionState}`))
                }
            };

            dataChannel.onopen = () => {
                console.log("Data channel is open");
                resolveAll();
            };

            dataChannel.onclose = () => {
                console.log("Data channel closed");
                closeSession()
            };

            dataChannel.onmessage = handleStreamedMessage.bind(null, registered_datachannel_handlers, true);

            dataChannel.onerror = (error) => {
                rejectAll(error)
            };

            try {
                const offer = await peerConnection.createOffer();
                await peerConnection.setLocalDescription(offer);

                request_action(websocket, 'post_web_rtc_signal', { sdp: peerConnection.localDescription });
            } catch (error) {
                rejectAll(error)
            }
        }
    });
}