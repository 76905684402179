import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header/Header';
import styles from './Home.module.css';
import { BiSolidUser } from "react-icons/bi";


const Home = () => {
    const [worlds, setWorlds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get('/api/worlds')
            .then(({ data }) => {
                setWorlds(data)
            }).catch((err) => {
                console.error('Error fetching the worlds:', err)
            });
    }, []);

    const filteredWorlds = worlds.filter(world =>
        world.world_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        world.world_desc?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="home">
            <Header onSearch={setSearchTerm} />
            <main>
                {filteredWorlds.map(world => (
                    <a href={`/w/${world.world_id}`}>
                        <div className={styles.card} key={world.world_id}>
                            {
                                !!world.thumbnail_url ?
                                    <img src={world.thumbnail_url} alt={world.world_name} />
                                    :
                                    <div className={styles.placeholder}>
                                        No image
                                    </div>

                            }
                            <h2>{world.world_name}</h2>
                            <p><BiSolidUser></BiSolidUser> {world.current_players} Players</p>
                        </div>
                    </a>
                ))}
            </main>
        </div>
    );
};

export default Home;