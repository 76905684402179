import React from 'react'
import styles from './InfoBox.module.css'

const InfoBox = ({ label, children }) => (
    <div className={styles.info_box}>
        <div className={styles.info_top}>{label}</div>
        <div className={styles.info_bottom}>{children}</div>
    </div>
);

export default InfoBox;