import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const JoinWorld = () => {
    const { world_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const joinWorld = async () => {
            try {
                const response = await axios.post(`/api/worlds/${world_id}/join`);
                const { world_instance_id } = response.data;
                navigate(`/p/${world_instance_id}`)
            } catch (error) {
                console.error('Error joining world:', error);
            }
        };
        joinWorld();
    }, [world_id, navigate]);

    return (
        <div>
            <Header />
            Joining world...
        </div>
    )
};

export default JoinWorld;