import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/Header/Header';
import styles from './WorldDetail.module.css';
import InfoBox from '../../components/InfoBox/InfoBox';

const WorldDetail = () => {
    const { world_id } = useParams();
    const [world, setWorld] = useState(null);

    useEffect(() => {
        const fetchWorld = async () => {
            try {
                const response = await axios.get(`/api/worlds/${world_id}`); // Use your backend URL
                setWorld(response.data);
            } catch (error) {
                console.error('Error fetching the world details:', error);
            }
        };
        fetchWorld();
    }, [world_id]);

    if (!world) {
        return <div>Loading...</div>;
    }

    const extraLinks = [
        world.world_status === 'ready' && {
            name: 'Join World', href: `/w/${world_id}/join`
        },
    ];

    return (
        <div className={styles.world_detail}>
            <Header links={extraLinks} />
            <main>
                <div className={styles.left}>
                    {
                        !!world.thumbnail_url ?
                            <img src={world.thumbnail_url} alt={world.world_name} width={400} height={400} />
                            :
                            <div className={styles.placeholder}>
                                No image
                            </div>
                    }
                </div>
                <div className={styles.right}>
                    <InfoBox label="Name">{world.world_name}</InfoBox>
                    <InfoBox label="Description">{world.world_description}</InfoBox>
                    <InfoBox label="Status">{world.world_status}</InfoBox>
                    <InfoBox label="Total Plays">{world.total_plays}</InfoBox>
                    <InfoBox label="Current Active Players">{world.total_active_players}</InfoBox>
                </div>
            </main>
        </div>
    );
};

export default WorldDetail;