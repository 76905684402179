import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/Header/Header';
import styles from './CreateWorld.module.css';
import InfoBox from '../../components/InfoBox/InfoBox';

const CreateWorld = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/api/worlds', { name, description });
            const { world_id } = response.data;
            navigate(`/w/${world_id}`)
        } catch (error) {
            console.error('Error creating world:', error);
            setLoading(false);
        }
    };

    return (
        <div className={styles.create_world}>
            <Header />
            <main>
                <div className={styles.left}>
                    <div className={styles.placeholder}>
                        {loading ? 'Generating...' : 'Hit Generate to create an image!'}
                    </div>
                </div>
                <div className={styles.right}>
                    <InfoBox label="Name">
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} disabled={loading} />
                    </InfoBox>
                    <InfoBox label="Description">
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} disabled={loading} />
                    </InfoBox>
                    <button onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Creating...' : 'Generate'}
                    </button>
                </div>
            </main>
        </div>
    );
};

export default CreateWorld;