import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

const Header = ({ onSearch, links }) => {
    return (
        <header>
            <a href='/'><h1>dngn</h1></a>
            <div className={styles.controls}>
                <Link to="/w">Create World</Link>
                {onSearch && (
                    <input
                        type="text"
                        placeholder="Search worlds..."
                        onChange={(e) => onSearch(e.target.value)}
                    />
                )}
            </div>
            {links && links.length > 0 && (
                <div className={styles.extra_links}>
                    {links.map((link, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && ' | '}
                            <Link to={link.href}>{link.name}</Link>
                        </React.Fragment>
                    ))}
                </div>
            )}
        </header>
    );
};

export default Header;