import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import WorldDetail from './pages/WorldDetail/WorldDetail';
import CreateWorld from './pages/CreateWorld/CreateWorld';
import JoinWorld from './pages/JoinWorld/JoinWorld';
import PlayWorld from './pages/PlayWorld/PlayWorld';
import axios from 'axios';

axios.defaults.withCredentials = true;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="w" element={<CreateWorld></CreateWorld>} />
        <Route path="w/:world_id" element={<WorldDetail></WorldDetail>} />
        <Route path="w/:world_id/join" element={<JoinWorld></JoinWorld>} />
        <Route path="p/:world_instance_id" element={<PlayWorld></PlayWorld>} />
      </Routes>
    </Router>
  );
}

export default App;